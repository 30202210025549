import { axios } from "@/utils/request";

export function fetchClubInfo(id) {
  return axios({
    url: `/club/${id}`,
    method: "get"
  });
}
export function fetchClubArticle(params) {
  return axios({
    url: `/writings`,
    method: "get",
    params
  });
}
export function fetchClubNotice(params) {
  return axios({
    url: `/writings`,
    method: "get",
    params
  });
}
